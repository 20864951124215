import React from "react"

const AllButtons = (props: any) => (
	<button
		className={props.className ? props.className : "MainButton"}
		style={{
			backgroundColor: props.buttonBGColor
				? props.buttonBGColor
				: "var(--lightgray)",
			color: props.buttonTextColor ? props.buttonTextColor : "var(--black)",
			alignItems: "center",
			border: "1px solid transparent",
			borderRadius: "4px",
			cursor: "pointer",
			display: "flex",
			fontWeight: "500",
			height: "36px",
			justifyContent: "center",
			lineHeight: "16px",
			margin: "var(--single)",
			minWidth: "180px",
			order: props.otherProps.order || 0,
			outline: "none",
			userSelect: "none",
		}}
		{...props.otherProps}>
		{props.otherProps.text}
	</button>
)

export const MainButton = (props: any) => <AllButtons otherProps={props} />

export const GreenButton = (props: any) => (
	<AllButtons
		className={`${props.className} GreenButton`}
		buttonBGColor="var(--green)"
		buttonTextColor="var(--white)"
		otherProps={props}
	/>
)

export const SecondaryButton = (props: any) => (
	<AllButtons
		className={`SecondaryButton ${props["data-signin"] && "signIn"}`}
		buttonBGColor="var(--blue)"
		buttonTextColor="var(--white)"
		otherProps={props}
	/>
)

export const TertiaryButton = (props: any) => (
	<AllButtons
		className={`${props.className} TertiaryButton`}
		buttonBGColor="var(--pink)"
		buttonTextColor="var(--white)"
		otherProps={props}
	/>
)

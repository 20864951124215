import * as React from "react"
import firebase from "firebase/compat/app"
import {
	initializeAuth,
	indexedDBLocalPersistence,
	browserLocalPersistence,
	browserSessionPersistence,
	signInAnonymously,
} from "firebase/auth"
import { SecondaryButton } from "./Buttons"
import { StaticImage } from "gatsby-plugin-image"

const app = firebase.initializeApp({
	apiKey: "AIzaSyAc3l1-sfLlz0pLrBnqrMv5ajk8gGkJUbA",
	authDomain: "auth.googlefeud.com",
	projectId: "gfeud2022",
	storageBucket: "gfeud2022.appspot.com",
	messagingSenderId: "1021061313029",
	appId: "1:1021061313029:web:c64f8baf3b7dead67ae26c",
	measurementId: "G-XTQ5L45WJP",
})
const auth = initializeAuth(app, {
	persistence: [
		indexedDBLocalPersistence,
		browserLocalPersistence,
		browserSessionPersistence,
	],
})

const SignInButton = () => {
	const [user, setUser] = React.useState(auth.currentUser)

	React.useEffect(() => {
		const signInAnonymouslyAsync = async () => {
			const anonUser = await signInAnonymously(auth)
			setUser(anonUser.user)
		}
		const unregisterAuthObserver = auth.onAuthStateChanged((currentUser) => {
			setUser(currentUser)
			if (currentUser === null) {
				signInAnonymouslyAsync()
			}
		})
		return () => unregisterAuthObserver()
	}, [])

	if (user && !user.isAnonymous) {
		if (user.photoURL) {
			return (
				<img
					style={{ height: "var(--double)" }}
					src={user.photoURL}
					alt={`Profile - ${user.displayName}`}
				/>
			)
		}
		return (
			<StaticImage
				style={{
					height: "var(--double)",
					width: "var(--double)",
				}}
				alt={`Profile - ${user.displayName}`}
				src="../images/user.png"
				loading="eager"
				objectFit="scale-down"
			/>
		)
	}
	return <SecondaryButton text="Sign In" data-signin />
}
export default SignInButton

import React from "react"

const RightPortrait = () => (
	<div
		className="hideOnMobile"
		style={{
			flex: 1,
			marginRight: "var(--half)",
			marginTop: "var(--double)",
		}}>
		<div data-fuse="22969287151"></div>
	</div>
)
export default RightPortrait

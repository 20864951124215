import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

type Props = {
	staticQuery: string
}
const MetaData = ({ staticQuery }: Props) => {
	const { description, title } = useStaticQuery(
		graphql`
			query PageQuery {
				site {
					siteMetadata {
						description
						title
					}
				}
			}
		`
	).site.siteMetadata
	const site_title = staticQuery ? `${title} - Answers - ${staticQuery}` : title

	return (
		<>
			<Helmet>
				<meta charSet="UTF-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=no"
				/>
				<meta name="description" content={description} />
				<meta http-equiv="content-language" content="en-US" />
				<meta
					name="keywords"
					content="google games, google trivia, feud, fued, google game, google search game, autocomplete, auto complete, funny, google suggest, autocorrect, autocompete, app, let's play, @midnight, chris hardwick, pewdiepie, smosh, webbys"
				/>

				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://googlefeud.com/" />
				<meta property="og:title" content={site_title} />
				<meta
					property="og:image"
					content="https://googlefeud.com/images/site_image.jpg"
				/>
				<meta property="og:description" content={description} />

				<meta name="twitter:url" content="https://googlefeud.com/" />
				<meta name="twitter:card" content="photo" />
				<meta
					name="twitter:image"
					content="https://googlefeud.com/images/site_image.jpg"
				/>
				<meta name="twitter:image:width" content="800" />
				<meta name="twitter:image:height" content="508" />
				<meta name="twitter:title" content={site_title} />
				<meta name="twitter:description" content={description} />

				<base target="_blank" />
				<script type="application/ld+json">
					{`
							{
								"@context": "http://schema.org/",
								"@type": "VideoGame",
									"award":"Webby Award - Best Game - People's Voice ",
									"dateCreated":"2013-04-23",
									"description": "${description.replace(/"/g, '\\"')}",
									${staticQuery ? `"gameItem":"Answers - ${staticQuery}",` : ""}
									"image":"https://googlefeud.com/images/site_image.jpg",
									"name":"Google Feud",
									"numberOfPlayers":"1",
									"typicalAgeRange":"13+",
									"url":"https://googlefeud.com",
									"inLanguage": "English",
								"author": {
									"@type": "Person",
									"name": "Justin Hook",
									"url": "https://justinhook.com/"
								},
								"genre": ["Trivia", "Quiz"],
								"gamePlatform": "Browser",
								"sameAs": [
									"https://en.wikipedia.org/wiki/Google_Feud",
									"https://poki.com/en/g/google-feud",
									"https://crazygames.com/game/google-feud"
								]
							}
						`}
				</script>
			</Helmet>
			<div style={{ position: "absolute", left: "-10000px" }}>
				{description}
			</div>
		</>
	)
}
export default MetaData

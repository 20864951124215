import * as React from "react"

import MainTemplate from "./src/templates/MainTemplate"

import "normalize.css"
import "./src/styles/Global.css"
import "./src/styles/Template.css"
import "./src/styles/Buttons.css"
import "./src/styles/DarkMode.css"

export const wrapPageElement = ( props ) => {
	return <MainTemplate {...props} />
}

import * as React from "react"
import { Link } from "gatsby"
import SignInButton from "./SignInButton"
import { MoonIcon } from "@heroicons/react/solid"
import { Helmet } from "react-helmet"

export const Header = () => {
	const prefersDarkMode =
		typeof window !== "undefined" &&
		window.matchMedia("(prefers-color-scheme: light)").matches
	const [isDarkMode, setIsDarkMode] = React.useState(
		typeof localStorage !== "undefined" && localStorage.getItem("theme")
			? localStorage.getItem("theme") === "dark"
			: prefersDarkMode
			? true
			: false
	)
	React.useEffect(() => {
		if (isDarkMode) {
			document.documentElement.classList.add("dark")
		} else {
			document.documentElement.classList.remove("dark")
		}
	}, [isDarkMode])
	const handleDarkMode = (isDark: boolean) => {
		setIsDarkMode(isDark)
		typeof localStorage !== "undefined" &&
			localStorage.setItem("theme", isDark ? "dark" : "light")
	}

	return (
		<>
			<Helmet
				meta={[
					{
						name: `theme-color`,
						content: isDarkMode ? "#202124" : "#FFFFFF",
					},
				]}></Helmet>
			<ul className="Header HeaderFooter" style={{ padding: "var(--half) 0" }}>
				<div key="headerMenuLeft" className="menuLeft">
					<li key="home">
						<Link to="/">Home</Link>
					</li>

					<li key="feudle">
						<Link to="/feudle/">Feudle</Link>
					</li>
					<li key="store">
						<Link to="/store/">Store</Link>
					</li>
				</div>
				<div key="headerMenuRight" className="menuRight">
					<li key="dark" className="h-full">
						<MoonIcon
							className="h-5 w-5"
							style={{ cursor: "pointer" }}
							onClick={() => handleDarkMode(!isDarkMode)}
						/>
					</li>
					<li key="profile" id="profile">
						<Link to="/profile/" id="signInLink">
							<SignInButton />
						</Link>
					</li>
				</div>
			</ul>
		</>
	)
}

export default Header

import React from "react"

const LeftPortrait = () => (
	<div
		className="hideOnMobile"
		style={{
			flex: 1,
			marginLeft: "var(--half)",
			marginTop: "var(--double)",
		}}>
		<div data-fuse="22969192176"></div>
	</div>
)
export default LeftPortrait

import * as React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import MetaData from '../components/MetaData';

import FooterBanner from '../components/Ads/FooterBanner';
import HeaderBanner from '../components/Ads/HeaderBanner';
import LeftPortrait from '../components/Ads/LeftPortrait';
import RightPortrait from '../components/Ads/RightPortrait';

type Props = {
	element: React.ReactNode;
	props: {
		staticQuery: string;
		location: {
			hash: string;
			pathname: string;
		};
	};
};
const MainTemplate = ({ element, props }: Props) => {
	const { staticQuery, location } = props;
	console.log(location.pathname);
	const showAds = location.hash !== '#noads' && location.pathname !== '/404/';
	return (
		<>
			<MetaData staticQuery={staticQuery} />
			<main
				style={{
					display: 'flex',
					flexDirection: 'column',
					flex: '1',
					minHeight: '100vh',
				}}>
				<Header />
				<div
					style={{
						flex: 1,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-evenly',
						minHeight: '600px',
						marginTop: 'var(--half)',
					}}>
					{showAds && <LeftPortrait />}
					<div
						className="ElementWrapper"
						style={{
							textAlign: 'center',
							minHeight: '600px',
							maxWidth: '90vw',
						}}>
						{showAds && (
						<HeaderBanner />
				)}
						{element}
						{showAds && (
						<FooterBanner />
				)}
					</div>
					{showAds && <RightPortrait />}
				</div>
				<div style={{ textAlign: 'center', width: '90%', color: '#999', paddingLeft: '5%' }}>
					<i>Google Feud</i> is a work of parody and not affiliated with Google
					LLC. "Google" and the Google logo are registered trademarks of Google
					LLC.
				</div>
				<Footer />
			</main>
		</>
	);
};
export default MainTemplate;
